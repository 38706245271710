<script context="module">
	export function updateWacp(
		analytics_storage,
		personalization_storage,
		ad_storage,
		ad_user_data,
		ad_personalization
	) {
		window.dataLayer.push({
			event: 'update-cookies-consent',
			analytics_storage,
			personalization_storage,
			ad_storage,
			ad_user_data,
			ad_personalization
		})
	}
</script>

<script lang="ts">
	// import { createEventDispatcher } from 'svelte'
	import { LoaderCircle } from 'lucide-svelte'
	import { fade } from 'svelte/transition'
	import { page } from '$app/stores'
	import { Form } from '$lib/form'
	import { SETTINGS, PRIVACY, DOCUMENTS, COOKIE_TERMS } from '$lib/routes'
	import { t } from '$lib/translations'
	// const dispatch = createEventDispatcher()
	let value: string
	// function sendHideSignal() {
	// 	dispatch('message', {
	// 		showWacpBanner: false
	// 	})
	// }
	import { setWpConsent } from '$lib/analytics/WpPixel'

	const fullText = t('web.cookies.infotext', {
		settingsLink: `/${SETTINGS}/${PRIVACY}`,
		termsLink: `/${DOCUMENTS}/${COOKIE_TERMS}`
	})
	const shortText = `${fullText?.substring(0, 120) || ''}...`
	let showFullText = false
	let showLoading = false
</script>

<section
	class="wacp wacp-shadow max-w-screen fixed !inset-x-0 bottom-0 z-[100000] mx-auto flex flex-col
		items-center justify-between gap-1 border border-neutral-200 bg-white p-3 text-neutral-800 md:w-fit md:rounded-xl md:p-4 lg:bottom-6 lg:px-4 lg:py-4"
	in:fade|global
>
	<h4 class="unstyled m-0 !mt-0 text-center text-lg font-extrabold md:mb-2 lg:text-xl">
		{t('web.cookies.privacyCare')}
	</h4>

	<p class="link-wrapper f-w-normal mb-3-res max-w-xl text-center text-xs leading-relaxed">
		<span class="{showFullText ? 'block' : 'hidden'} md:block">{fullText}</span>
		<span class:hidden={showFullText} class="md:hidden"
			>{shortText}
			<button class="whitespace-nowrap underline" on:click={() => (showFullText = true)}
				>{t('web.cookies.infotext.showMore')}
				<img
					class="float-end ml-[.45em] mt-[.45em] aspect-square h-[.75em] rotate-90"
					src="/images/icons/arrow/next-black-simple.svg"
					alt="down arrow icon"
				/></button
			></span
		>
	</p>

	<div class="flex w-full flex-wrap items-center justify-center gap-2 lg:mt-0">
		<Form
			cls="flex gap-3 justify-center items-center flex-wrap w-full"
			action="/{SETTINGS}/{PRIVACY}"
			updatePage={false}
			noScrollReset
		>
			<input type="hidden" name="analytics" {value} />
			<input type="hidden" name="personalization" {value} />
			<input type="hidden" name="ad" {value} />
			<input type="hidden" name="referer" value={$page.url.href} />
			<div class="flex flex-wrap justify-center gap-2">
				<button
					class="btn btn-new btn-new-red my-0 flex h-fit min-w-[50%] gap-2 py-2.5 !text-sm leading-none transition-opacity md:min-w-40 {showLoading &&
						value === 'granted' &&
						'pointer-events-none opacity-70'}"
					data-testid="cookies-consent-accept"
					on:click={() => {
						value = 'granted'
						// sendHideSignal()
						showLoading = true
						updateWacp('granted', 'granted', 'granted', 'granted', 'granted')
						setWpConsent(true)
					}}
					>{#if showLoading && value === 'granted'}<LoaderCircle
							color="white"
							size={16}
							class="animate-spin"
						/>{t('web.forms.submit.sending')}{:else}{t(
							'web.cookies.infotext.acceptAll'
						)}{/if}</button
				>
				<button
					class="btn btn-new btn-new-outline h-fit py-2.5 !text-sm leading-none transition-opacity {showLoading &&
						value === 'denied' &&
						'pointer-events-none opacity-70'}"
					data-testid="cookies-consent-reject"
					on:click={() => {
						value = 'denied'
						// sendHideSignal()
						showLoading = true
						updateWacp('denied', 'denied', 'denied', 'denied', 'denied')
						setWpConsent(false)
					}}
					>{#if showLoading && value === 'denied'}<LoaderCircle
							color="black"
							size={16}
							class="animate-spin"
						/>{t('web.forms.submit.sending')}{:else}{t(
							'web.cookies.infotext.rejectAll'
						)}{/if}</button
				>
				<a
					class="md:btn md:btn-new md:btn-new-outline mt-1 h-fit w-full text-center !text-sm leading-none underline md:mt-0 md:w-fit md:py-2.5 md:no-underline"
					href="/{SETTINGS}/{PRIVACY}"
					data-testid="cookiesConsent-settings"
				>
					{t('web.cookies.infotext.settings')}
				</a>
			</div>
			<!-- <a
				class="h-fit w-full gap-2 bg-transparent py-3 text-sm leading-none hover:underline sm:w-fit"
				href="/{SETTINGS}/{PRIVACY}"
				data-testid="cookiesConsent-settings"
			>
				{t('web.cookies.infotext.settings')}
			</a> -->
		</Form>
	</div>
</section>

<style>
	.wacp-shadow {
		box-shadow: rgba(0, 0, 0, 0.25) 0px 7px 18px;
	}
</style>
